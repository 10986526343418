<template>
  <BaseCard>
    <BaseCardHeader
      bg="bg-secondary"
      class="mb-6"
    >
      <template slot="icon">
        <Calculator />
      </template>
      Usage
    </BaseCardHeader>
    <div v-if="hasLoaded">
      <p class="my-4 text-gray-700">
        Used <strong class="text-2xl font-medium">{{ unlockedString }}</strong> test attempts
        <span v-if="ofTotalString">
          of <strong class="text-2xl font-medium">{{ ofTotalString }}</strong>
        </span>
      </p>
      <UsageBar
        :used="usage.unlockedAttempts"
        :total="usage.attemptResultsLimit"
      />
      <p
        v-if="usage.end"
        class="my-4"
      >
        Your usage updates on {{ formatDate(parseISO(usage.end)) }}
      </p>
      <p
        v-if="usage.annulledAttempts"
        class="my-4 text-sm"
      >
        Includes {{ usage.annulledAttempts.toLocaleString() }} attempts from deleted candidates
      </p>
      <div v-if="usage.lockedAttempts > 0">
        <p class="my-4">
          <BaseButton
            :to="{ name: 'settings-plan' }"
          >
            Upgrade to unlock {{ usage.lockedAttempts.toLocaleString() }} test attempts
          </BaseButton>
        </p>
      </div>
      <div v-else-if="!usage.plan">
        <p class="my-4">
          You’re not currently on a plan
        </p>
        <p class="my-4">
          <BaseButton
            :to="{ name: 'settings-plan' }"
          >
            Choose a plan to unlock more tests
          </BaseButton>
        </p>
      </div>
    </div>
    <div
      v-else
      class="mt-8"
    >
      <Loader />
    </div>
  </Basecard>
</template>

<script>
import Calculator from '@components/Icons/Calculator'
import UsageBar from '@components/Settings/UsageBar'
import Loader from '@components/Loader'

import billingApi from '@api/billing'
import { mapGetters } from 'vuex'
import { parseISO } from 'date-fns'
import { formatCurrency } from '@utils/currency'
import { formatDate } from '@utils/formatDate'

export default {
  components: {
    Loader,
    Calculator,
    UsageBar
  },

  data() {
    return {
      formatDate,
      parseISO,
      formatCurrency,
      hasLoaded: false,
      usage: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    }),

    unlockedString() {
      if (this.usage.attemptResultsLimit &&
        this.usage.unlockedAttempts > this.usage.attemptResultsLimit) {
        // @NB 2021-09-14 Only show the total as the limit
        // The total is more than the limit, but we don’t want people to see
        // they’ve gone over their total, so we limit this to the lowest.
        // They’ve got more unlocked than locked, so we just return the limit
        return this.usage.attemptResultsLimit.toLocaleString()
      }

      return this.usage.unlockedAttempts.toLocaleString()
    },

    ofTotalString() {
      if (this.usage.attemptResultsLimit) {
        // @NB 2021-09-14 Always return the limit if it’s set
        // Previosuly, we would only return this if it didn’t look confusing
        // using this.usage.attemptResultsLimit >= this.usage.unlockedAttempts
        return this.usage.attemptResultsLimit.toLocaleString()
      }
      return null
    }
  },

  created() {
    this.getUsage()
  },

  methods: {
    /**
     * Fetch the usage data
     */
    getUsage() {
      return billingApi.usage(this.organisationId)
        .then(response => {
          this.hasLoaded = true
          this.usage = response.data
        })
    }
  }
}
</script>
