<template>
  <BaseContainer>
    <UsageSettings />
  </BaseContainer>
</template>

<script>
import UsageSettings from '@components/Settings/UsageSettings'

export default {
  page: {
    title: 'Plan'
  },

  components: {
    UsageSettings
  }
}
</script>
