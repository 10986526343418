<template>
  <div>
    <div class="w-full">
      <div class="h-2 w-full bg-gray-100">
        <div
          :class="['leading-none h-full bg-gray-800']"
          :style="barStyle"
        >
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    used: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },

  computed: {
    /**
     * @return {string}
     */
    barStyle() {
      return `width: ${this.percentageUsed}%`
    },

    /**
     * @return {Number}
     */
    percentageUsed() {
      const percent = this.used / this.total * 100
      if (percent > 100) {
        // Likely due to an override at some point
        return 100
      }
      if (percent < 0) {
        // No idea but you never know
        return 0
      }
      return percent
    }
  }
}
</script>
