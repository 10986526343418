<template>
  <div>
    <SettingsNavigation />

    <div class="max-w-screen-xl mx-auto px-6">
      <UsageCard />
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import UsageCard from '@components/Settings/UsageCard'

export default {
  components: {
    SettingsNavigation,
    UsageCard
  }
}
</script>
